@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.signup-btn:hover {
  background-color: unset !important;
}

/* div[role="cell"]{
  justify-content: center !important;
}

div[role="columnheader"]{
  justify-content: center !important;
} */
div[data-column-id="actions"] {
  justify-content: flex-end;
}

.tabs-container {
  display: flex;
  justify-content: flex-end; /* Pulls the button to the right */
}
.dialerTabs button[aria-selected="true"] {
  color: white;
  font-weight: bold;
  border-bottom: solid;
}
.dialerTabs button[aria-selected="false"] {
  color: gray;
}
.transcription-box {
  height: 100vh;
}

/* FOR CHATBOT */
.chat-boxes {
  border: 1px solid white;
  padding: 5px 10px;
  border-radius: 15px;
}

.person-chat {
  border-color: green;
  color: green;
}
/* END OF CHATBOT STYLES */

/* CUSTOM CSS FOR FLOWGEN */

/* Add this to your CSS or styles file */
.react-flow__node {
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 0px !important;
}


.node-header {
  background-color: #0073c9;
  padding: 5px;
  color: white;
  overflow: hidden;
  border-radius: 5px 5px 0px 0px;
  margin-top: -7px;
}
.node-body {
  padding: 5px;
}

/* Settings Page Styles */
.button-group button {
  font-weight: bold;
  padding: 5px 20px;
}
.button-group .btn-active {
  font-weight: bold;
  background-color: #e2e8f0;
}
.settings-contents {
  border-radius: 10px;
  border: solid 1px #e8e8e8;
}

/* End of Settings Page Styles */

.status-success div:nth-child(5) > div {
  color: green;
  border-radius: 10px;
  background-color: #bcffbf;
  padding: 5px 10px;
}
.status-ongoing div:nth-child(5) > div {
  color: orange;
  border-radius: 10px;
  background-color: #ffe6bb;
  padding: 5px 10px;
}
/* div[role="columnheader"] > div{
  text-wrap: wrap
   !important;
} */
.steps,
.cat-text {
  overflow: visible !important;
  text-wrap: wrap;
}
.cat-value {
  color: darkgray;
}
.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 10px;
  width: 10px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}
.blob.green {
  background: rgba(51, 217, 178, 1);
  box-shadow: 0 0 0 0 rgba(51, 217, 178, 1);
  animation: pulse-green 2s infinite;
}

@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(51, 217, 178, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(51, 217, 178, 0);
  }
}
/* Status Pulse Animation */

.status {
  background: green;
  border-radius: 50px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  padding: 5px 10px;
  /* height: 20px;
	width: 20px; */
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.status.ongoing {
  background: rgba(255, 121, 63, 1);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
  animation: pulse-orange 2s infinite;
  color: white;
}

.status.end {
  background: none;
  color: red;
  margin: 0px;
  padding: 0px;
}

@keyframes pulse-orange {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}

.live-message-list .other-message {
  background-color: white;
}
.live-message-list .user-message {
  background-color: #3483eb;
  color: white;
  border: none;
}

.testcases-table {
  /* max-height: 55vh !important;
  overflow-y: auto; */
  border-radius: none !important;
}
.rdt_TableCol div:first-child {
  text-wrap: wrap !important;
}
.rdt_Table {
  height: 50vh !important;
}

.rdt_TableCell div:first-child {
  text-wrap: wrap !important;
}
.category_text > span {
  color: gray;
}
.dataset_table input {
  font-size: 10pt;
}

.react-flow__handle {
  width: 8px !important;
  height: 8px !important;
  border-radius: 50px !important;
}
.dialer-pads-container {
  justify-items: center;
}

::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #d2d4e2;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.react-flow__node-group {
  resize: both;
  min-width: 310px;
  width: max-content !important;
  height: max-content !important;
  box-shadow: none !important;
  border: none !important;
  background-color: none !important;
}

.react-flow__node-group.active {
  box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.9);
}

.react-flow__node-group.selected,
.react-flow__node-group:hover {
  box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.5);
}

.react-flow__node-group.selected {
  border-width: 2px;
}

.react-flow__node-toolbar {
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.5);
}

.react-flow__node-toolbar button {
  border-radius: 0;
  background: #eee;
  color: #111;
  font-size: 11px;
  border: none;
  padding: 5px 15px;
  cursor: pointer;
  font-weight: 700;
}

.react-flow__node-toolbar button:hover {
  background-color: #ddd;
}

.react-flow__node-toolbar button:nth-child(even) {
  border-left: 1px solid #999;
}

.react-flow__node {
  background-color: white;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  min-width: 310px;
  /* min-height: 200px; */
  position: relative;
  cursor: grab;

}

.react-flow__node_notes {
  background-color: white;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  min-width: 310px;
  min-height: 200px;
  position: relative;
  cursor: grab;
}

.react-flow__node-dsBlock {
  width: max-content;
  height: max-content;
}

.target {
  background: #16851b !important;
}

.source {
  background: cornflowerblue !important;
}

.react-flow__handle {
  transition: all 0.2s ease;
  /* width: 8px;
  height: 8px; */
}

.react-flow__handle:hover {
  transition: all 0.2s ease;
  width: 20px !important;
  height: 20px !important;
}

.excalidraw.excalidraw-modal-container {
  height: 100vh;
}

.react-flow__node-startBlock,
.react-flow__node-endBlock {
  min-width: 100px;
  min-height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
